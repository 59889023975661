
import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import * as types from '@/store/types';
import { EMAIL_REGEX } from '@/scripts/shared';
import { TranslateResult } from 'vue-i18n';
import { BASE_URL, PATH_EXTENSION } from '@/middlewares/httpClient';

const namespace: string = 'invitation';

@Component
export default class InviteUser extends Vue {
  @Action(types.INVITE_NEW_USER, { namespace }) inviteNewUser: any;

  @Getter(types.GET_INVITED_USER, { namespace }) getInvitedUser: any;

  private email = '';

  private loading = false;

  @Validations()
  validations = {
    email: {
      required,
      matches(value: string) {
        return EMAIL_REGEX.test(value);
      },
    },
  };

  get emailErrors() {
    const errors: TranslateResult[] = [];
    if (!this.$v.email.$dirty) return errors;

    if (!this.$v.email.matches) errors.push(this.$t('email_invalid'));
    if (!this.$v.email.required) errors.push(this.$t('required_field'));
    return errors;
  }

  get link() {
    if (this.getInvitedUser.token) {
      return `${BASE_URL}${PATH_EXTENSION}/signup?id=${this.getInvitedUser.token}`;
    }
    return '';
  }

  private async inviteUser() {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.loading = true;
    await this.inviteNewUser(this.email);
    this.loading = false;
  }

  private copyText() {
    const textToCopy = ((this.$refs.textToCopy as Vue).$el as SVGElement).querySelector('textarea');
    if (textToCopy) {
      textToCopy.select();
      document.execCommand('copy');
    }
  }
}
