

import { Action, Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';
import * as types from '@/store/types';
import { TranslateResult } from 'vue-i18n';

const namespace: string = 'user';

@Component({
  components: {
    Editor,
  },
})
export default class SendAllUsers extends Vue {
  // Actions
  @Action(types.SEND_MESSAGE_TO_ALL_USERS, { namespace }) sendMesssageToAll: any;

  // Getters
  @Getter(types.GET_USER, { namespace }) getUser: any;

  // Data
  private valid = false;

  private subject = '';

  private dialog = false;

  private editorText: string = '';

  private editorOptions: any = {
    language: this.$i18n.locale,
    usageStatistics: false,
  };

  // Vue Life Cycle Hooks
  created() {
    this.editorText = this.$t('default_email_body').toString();
  }

  // Methods
  private checkForm() {
    const { form }: any = this.$refs;

    form.validate();

    if (this.valid) {
      this.dialog = true;
    }
  }

  private onSendClicked() {
    const { form, toastuiEditor }: any = this.$refs;

    form.validate();

    const html = toastuiEditor.invoke('getHtml');

    if (this.valid && html) {
      this.sendMesssageToAll({
        id: this.getUser.id,
        subject: this.subject,
        message: html,
      });
    }

    this.dialog = false;
  }
}

