
import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import * as types from '@/store/types';
import { PlatformConfig } from '@/store/platformConfig/models';

const namespace: string = 'platformConfig';

@Component
export default class GeneralSettings extends Vue {
  @Getter(types.GET_PLATFORM_CONFIG, { namespace })
  getPlatformConfig: any;

  @Action(types.UPDATE_PLATFORM_CONFIG, { namespace })
  updatePlatformConfig: any;

  get setToggle() {
    return this.getPlatformConfig.enableSignup;
  }

  set setToggle(value: boolean) {
    const config: PlatformConfig = {
      enableSignup: value,
      apiBasePath: this.getPlatformConfig.apiBasePath,
    };
    this.updatePlatformConfig(config);
  }
}
